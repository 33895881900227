import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux";
import {
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'

import { updateCartMiddleware } from "./store-middleware";

//user slices
import userReducer from './slices/userSlice';
import settingsReducer from './slices/settingsSlice';
import bookmarkReducer from './slices/bookmarkSlice';

//organizer slices
import organizerReducer from './slices/organizer-slice/organizerSlice';

const rootReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  bookmark: bookmarkReducer,

  organizer: organizerReducer,
  
})

//check if window variable, to fix storage for redux-persist
//const isclient = typeof window !== "undefined";
const isclient = typeof window !== "undefined";
let mainReducer;
if (isclient) {
  const { persistReducer } = require("redux-persist");
  const storage = require("redux-persist/lib/storage").default;

  const persistConfig = {
    key: 'root',
    storage: storage,
  }
  mainReducer = persistReducer(persistConfig, rootReducer)
} else {
  mainReducer = rootReducer
}

//define store
const store = configureStore({
  reducer: mainReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(updateCartMiddleware as any),
})
export default store;


//export variables
export const persistor = persistStore(store)


// create types for state and dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

