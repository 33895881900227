// components/SEOHead.tsx
import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

interface SEOHeadProps {
}

const SEOHead: React.FC<SEOHeadProps> = ({ }) => {
  const { locales, asPath, locale } = useRouter();
  //const defaultLocale = 'en'; // Your default locale
  const useLocale = locale || 'en'; // Use 'en' as the default locale if none is set


  const meta: Record<string, { title: string; description: string }> = {
    en: {
      title: "Ambition",
      description: "This is Ambition",
    },
    jp: {
      title: "アンビション",
      description: "ダンス探しアプリAmbition",
    },
  };
  // Fallback to defaultTitle and defaultDescription if the locale doesn't match
  const { title, description } = meta[useLocale] || { title: '', description: '' };


  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      {locales?.map((loc) => (
        <link
          key={loc}
          rel="alternate"
          hrefLang={loc}
          href={`/${loc}${asPath}`}
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href={`/${useLocale}${asPath}`} />
    </Head>
  );
};

export default SEOHead;
