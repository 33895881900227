import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import getConfig from 'next/config'



export const getBookmarks = createAsyncThunk(
  'bookmark/getBookmarks',
  async (_,{getState}) =>  {
    //get state to access token
    const userState:any = getState();
    const {user} = userState.user.data;


    let token;
    if ('token' in user) {  token = user.token } 

    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}

      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return userState.bookmark.data
      }

      // send axios request
      const response = await axios.get(
        `${backendUrl}/api/bookmark/get-bookmarks/`,
        config
      )

      return response.data
    }
  }
)


export const addBookmarkLesson = createAsyncThunk(
  'bookmark/addBookmarkLesson',
  async (lessonId:string, {getState}) => {

    //get state to access token
    const userState:any = getState();
    const {user} = userState.user.data;
    let token; if ('token' in user) {  token = user.token } 

    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}

      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return userState.bookmark.data
      }
      
      // send axios request
      const response = await axios.post(
        `${backendUrl}/api/bookmark/add-bookmark-lesson/`,
        {lessonId:lessonId},
        config
      )
      return response.data
    }
  }
)
export const addBookmarkOrganizer = createAsyncThunk(
  'bookmark/addBookmarkOrganizer',
  async (organizerId:string, {getState}) => {

    //get state to access token
    const userState:any = getState();
    const {user} = userState.user.data;
    let token; if ('token' in user) {  token = user.token } 

    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}

      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return userState.bookmark.data
      }
      
      // send axios request
      const response = await axios.post(
        `${backendUrl}/api/bookmark/add-bookmark-organizer/`,
        {organizerId:organizerId},
        config
      )
      return response.data
    }
  }
)

export const removeBookmarkLesson = createAsyncThunk(
  'bookmark/removeBookmarkLesson',
  async (lessonId:string, {getState}) => {

    //get state to access token
    const userState:any = getState();
    const {user} = userState.user.data;
    let token; if ('token' in user) {  token = user.token } 

    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}

      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return userState.bookmark.data
      }

      // send axios request
      const response = await axios.delete(
        `${backendUrl}/api/bookmark/remove-bookmark-lesson/${lessonId}/`,
        config
      )
      return response.data
    }
  }
)
export const removeBookmarkOrganizer = createAsyncThunk(
  'bookmark/removeBookmarkOrganizer',
  async (organizerId:string, {getState}) => {

    //get state to access token
    const userState:any = getState();
    const {user} = userState.user.data;
    let token; if ('token' in user) {  token = user.token } 

    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}

      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return userState.bookmark.data
      }

      // send axios request
      const response = await axios.delete(
        `${backendUrl}/api/bookmark/remove-bookmark-organizer/${organizerId}/`,
        config
      )
      return response.data
    }
  }
)



export interface BookmarkProps {
  lesson:[],
  organizer:[]
}


export interface BookmarkStateProps {
  data: BookmarkProps;
  status: string;
  error: string | null;
}

const initialState: BookmarkStateProps = {
  data:{lesson:[], organizer:[]},
  status: 'idle',
  error:'null'
};


export const bookmarkSlice = createSlice({
  name: 'bookmark',
  initialState: initialState,
  reducers: {
    resetBookmark: (state) => {
      state.data = {lesson:[], organizer:[]}
    }
  },
  extraReducers: (builder) => {

    // getBookmarks
    builder.addCase(getBookmarks.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getBookmarks.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(getBookmarks.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    // addBookmarkLesson
    builder.addCase(addBookmarkLesson.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(addBookmarkLesson.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(addBookmarkLesson.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })
    // addBookmarkOrganizer
    builder.addCase(addBookmarkOrganizer.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(addBookmarkOrganizer.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(addBookmarkOrganizer.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    // removeBookmarkLesson
    builder.addCase(removeBookmarkLesson.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(removeBookmarkLesson.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(removeBookmarkLesson.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })
    // removeBookmarkOrganizer
    builder.addCase(removeBookmarkOrganizer.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(removeBookmarkOrganizer.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(removeBookmarkOrganizer.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })
  },
});

//reducers
export const {resetBookmark} = bookmarkSlice.actions;


//export const selectUser = (state) = state.user
export default bookmarkSlice.reducer;
