import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


import { loadLessonPage, patchLesson, deleteLesson } from './lessonSlice';




/**
 * INTERFACE FOR DATA
 */
interface organizerProfileProps {
  name: string;
}

export interface LessonPageProps {
  lessonId: string;
  title: string;
  genre:string;
  targetAudience: string;
  description:string;
  instructor: {
    instructorId:string;
    name: string;
    genres:string;
    description:string;
    profilePicture:string;
  }
  price: number | null;
  published: boolean;
  created_at: Date | null;
  nextClassId: string;
  lastClassId: string;
  upcoming_classes: any[];
}




/**
 * INITIAL DATA
 */
export const blankOrganizer = {
  organizerId:'', 
  name:'',
}
export const blankLessonPage = {
  lessonId: '',
  title:'', genre:'', targetAudience:'', description:'', 
  instructor: {
    instructorId: '',
    name:'', genres:'',
    description:'',
    profilePicture:''
  },
  price:null,
  published:false, created_at:null,
  nextClassId:'', lastClassId:'',
  upcoming_classes:[]
}


/**
 * DATA INTERFACE SETUP
 */
// organizerSlice data interface
interface OrganizerDataProps {
  profile: organizerProfileProps | null;
  lessonPage: LessonPageProps | null
}

// whole redux interface with status & error
export interface OrganizerStateProps {
  data: {
    profile: organizerProfileProps | null,
    lessonPage: LessonPageProps | null;
  }
  status: string;
  error: string | null;
}

//organizerSlice data initial data
const initialData:OrganizerDataProps = {
  profile: blankOrganizer,
  lessonPage: blankLessonPage
}

//whole redux initial state with status & error
const initialState: OrganizerStateProps = {
  data:initialData,
  status: 'idle',
  error:'null'
};


export const organizerSlice = createSlice({
  name: 'organizer',
  initialState: initialState,
  reducers: {
    organizerLogin: (state, action) => {
      state.data = {profile: action.payload, lessonPage: blankLessonPage}
    },

    updateOrganizerProfile: (state, action) => {
      state.data = {...state.data, profile:action.payload}
    },

    resetOrganizerSlice: (state) => {
      state.data = initialData
    },
    resetLessonPage: (state) => {
      state.data = {...state.data, lessonPage:blankLessonPage}
    },
  },
  extraReducers: (builder) => {

    /* -------------- LESSON SLICE ---------------*/
    //loadLessonPage
    builder.addCase(loadLessonPage.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(loadLessonPage.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = {...state.data, lessonPage:action.payload}
    })
    builder.addCase(loadLessonPage.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    //patchLesson
    builder.addCase(patchLesson.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(patchLesson.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = {...state.data, lessonPage:action.payload}
    })
    builder.addCase(patchLesson.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    //deleteLesson
    builder.addCase(deleteLesson.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteLesson.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = {...state.data, lessonPage:action.payload}
    })
    builder.addCase(deleteLesson.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

  },
});

//reducers
export const {organizerLogin, updateOrganizerProfile, resetOrganizerSlice, resetLessonPage} = organizerSlice.actions;


//export const selectUser = (state) = state.user
export default organizerSlice.reducer;
